import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BuilderComponent, builder } from '@builder.io/react';

// Set your Builder API key
builder.init('ac5245d40be444c68001c3bb5c294cc4');

function BuilderPage() {
    const { jsonFile } = useParams();  // Capture the dynamic part of the URL
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJson = async () => {
            try {
                const response = await fetch(`/json/${jsonFile}.json`);
                if (!response.ok) {
                    throw new Error(`JSON file for ${jsonFile} not found`);
                }
                const data = await response.json();
                setJsonData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchJson();
    }, [jsonFile]);  // Refetch when the route changes

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <BuilderComponent model="page" content={jsonData} />
        </div>
    );
}

export default BuilderPage;
